@import url(./vendor/normalize.css);
@import url(./vendor/Inter/inter.css);

form * {
  outline: none;
}

.main_section {
  max-width: 100%;
}

.App {
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  margin: 0 auto;
  font-family: "Inter", Arial, Helvetica, sans-serif;
}

.header {
  width: 100%;
  background-color: #cfe3f5;
}

.header__link {
  color: #00bfff;
  position: relative;
  cursor: pointer;
  text-decoration: none;
}

.header__item {
  font-size: 18px;
  padding: 20px;
}

.header__link:after {
  content: "";
  display: block;
  position: absolute;
  right: 0;
  bottom: -3px;
  width: 0;
  height: 2px;
  background-color: rgb(132, 159, 162);
  transition: 0.5s;
}

.header__link:hover:after {
  content: "";
  width: 100%;
  display: block;
  position: absolute;
  left: 0;
  bottom: -3px;
  height: 2px;
  background-color: rgba(53, 155, 233, 0.3);
  transition: width 0.5s;
}

.header__navigation {
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  margin: 0;
  font-size: 1.1em;
  padding: 0 30px;
}
@media screen and (max-width: 600px) {
  .header__navigation {
    padding: 0;
  }
}

.header__link {
  text-decoration: none;
  color: black;
}

.heading {
  display: flex;
  background: url(./images/buhta.jpg) no-repeat;
  background-size: 1270px 217px;
  background-position: center;
  height: 217px;
}

/* .heading__name::before {
  content: "";
  padding: 10px 1200px;
  background-color: rgba(0, 0, 0, 0.2);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
} */

.heading__name {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1200px;
  font-size: 45px;
  color: #fff;
  margin: auto;
  text-align: center;
}
.important__video_desk {
  display: block;
}
.important__video_mob {
  display: none;
}

@media screen and (max-width: 690px) {
  .heading__name {
    font-size: 32px;
  }

  .heading {
    background: url(./images/buhta.jpg) no-repeat;
    background-size: 1270px 217px;
    background-position: center;
  }
}

.menu {
  margin-bottom: 20px;
  position: relative;
  list-style: none;
  background-color: rgba(53, 155, 233, 0.3);
  display: flex;
  background-color: #cfe3f5;
  flex-direction: row;
  height: 70px;
  justify-content: space-evenly;
  align-items: center;
}

.menu__link {
  text-decoration: none;
  font-size: 1.3rem;
}

@media screen and (max-width: 790px) {
  .menu {
    flex-direction: column;
    justify-content: center;
  }

  .menu__item {
    display: none;
  }

  .menu__item:hover {
    width: 90%;
  }

  .menu__link {
    text-decoration: none;
    width: 90%;
  }
}

.menu__item {
  color: #5a5a5a;
  transition: 0.5s linear;
  text-align: center;
}

.menu__item a {
  color: #5a5a5a;
  text-decoration: none;
}

.menu__item:hover {
  padding: 6px;
  text-align: center;
  vertical-align: center;
  background-color: rgba(126, 177, 216, 0.8);
  border-radius: 25px;
}

.main {
  max-width: 1280px;
  margin: 0 auto;
}

.intro {
  padding: 20px;
}

.main__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 20px 0 40px;
}

.main__img {
  max-width: 85vw;
  margin: 0 auto;
}

.intro__title {
  text-align: center;
}

.mark {
  display: block;
  font-size: 12px;
  height: 60px;
  width: 60px;
  text-align: center;
  transition: 0.5s linear;
  position: fixed;
  top: 450px;
  right: 40px;
  background-image: url(./images/arrowup.ico);
  background-size: contain;
  cursor: pointer;
}

.mark:hover {
  background-color: #70c7e3;
  border-radius: 50%;
}

.mark__top {
  color: rgb(56, 54, 54, 0.8);
  text-decoration: none;
  height: 30px;
  display: block;
  padding-top: 30px;
  height: 80px;
  width: 80px;
  margin: 0 10px 0 0;
  padding: 0 10px 0 0;
}

.main {
  padding: 20px;
}

.main__wrapper {
  display: flex;
}

.important {
  display: block;
  flex-grow: 3;
}

.important__list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.important__item {
  margin: 10px 0;
  font-size: 1.3em;
  padding: 15px 10px;
  text-align: justify;
}
.important__item_center {
  display: flex;
  flex-direction: column;
}
.important__imgs {
  display: flex;
  gap: 7px;
  width: 33%;
}
@media screen and (max-width: 600px) {
  .important__imgs {
    width: 100%;
    flex-wrap: wrap;
  }
}
.important__image {
  width: 100%;
}

.important__item:nth-child(2n) {
  background-color: rgba(207, 227, 245, 0.5);
  border-radius: 15px;
}

@media screen and (max-width: 1250px) {
  .important__img {
    display: flex;
    flex-direction: column;
  }
}

.write__email {
  width: 30%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.input__title {
  text-align: center;
}

.input__message {
  margin: 10px;
  padding: 5px;
  background-color: rgba(50, 111, 157, 0.2);
  border: 1px solid rgba(58, 108, 146, 0.8);
  border-radius: 8px;
}

.input__message:focus {
  box-shadow: 0 2px 0 gray;
  border: none;
}

.input__message_text {
  resize: vertical;
  min-height: 150px;
  text-overflow: ellipsis;
}

.input__button {
  transition: 0.5s linear;
  font-family: "Montserrat";
  font-size: 15px;
  color: gray;
  padding: 14px 25px;
  border-radius: 18px;
  border: 3px hidden #faf9f5;
  background: #d3d7dd;
  background: linear-gradient(to top, #d3d7dd, #8abbe0);
  width: 80%;
  letter-spacing: 2px;
  align-self: center;
  top: 0;
  position: relative;
  transition: 0.5s linear;
}

.input__button:hover {
  background: rgba(25, 134, 218, 0.486);
  color: #353535;
  transition: 0.5s linear;
}

.input__button:active {
  top: 3px;
  box-shadow: 0 2px 0 rgb(65, 64, 64);
}

.footer {
  background-color: rgba(37, 97, 143, 0.2);
  padding: 20px;
}

.footer__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.footer__title {
  text-align: center;
  color: #939fa7;
}

.footer__item {
  max-width: 350px;
  display: block;
  margin: 20px;
  color: #555555;
}

@media screen and (max-width: 690px) {
  .footer__wrapper {
    display: flex;
    flex-direction: column;
  }

  .footer__item {
    text-align: center;
  }
  .footer__container {
    max-width: 400px;
  }
}

.footer__link {
  text-decoration: none;
  color: black;
}

.documents {
  margin: auto;
  max-width: 880px;
}

.documents__item {
  list-style: none;
  margin: 20px;
}

.contacts {
  padding: 20px;
  margin: 0 auto;
}

.contacts__phone {
  font-size: 23px;
  padding: 15px;
}

.contacts__list {
  padding: 20px;
  list-style: none;
}

.contacts__item_title {
  width: 100%;
  background: aliceblue;
  border-radius: 20px;
  font-weight: bold;
  padding: 20px 10px;
  margin: 10px;
  display: block;
}

.contacts__item {
  padding-top: 7px;
  font-size: 25px;
  line-height: 30px;
}

.contacts__subtitle {
  padding: 25px 10px;
  font-weight: 700;
}

.popup {
  align-items: center;
  background-color: rgba(7, 7, 7, 0.6);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  opacity: 0;
  position: fixed;
  top: 0;
  transition: visibility 0.2s, opacity 0.7s ease;
  visibility: hidden;
  width: 100%;
}

.popup__container {
  background-color: #ffffff;
  border-radius: 10px;
  box-sizing: border-box;
  color: #000;
  padding: 30px 36px;
  position: relative;
  width: 90%;
}

.popup__title {
  text-align: center;
  margin: 0;
  padding-bottom: 20px;
}

.popup__text {
  color: red;
}

.fire-safety {
  font-size: 28px;
}

.fire-safety__title {
  text-align: center;
  font-size: 30px;
  padding: 0 0 20px;
}

.fire-safety__image {
  background-image: url("./images/пожарка.jpg");
  background-repeat: no-repeat;
  background-size: contain;
  padding: 50% 0%;
}

.fire-safety__subtitle {
  text-align: center;
  font-size: 28px;
}

.fire-saferty__text_important {
  display: block;
  font-size: 28px;
  margin-bottom: 10px;
  padding: 0px;
}

.fire-saferty__text {
  padding: 15px;
  font-size: 22px;
  line-height: 27px;
  display: block;
  text-align: justify;
  font-weight: bold;
}

.fire-saferty__item {
  margin-top: 10px;
  font-weight: normal;
}

.energy {
  padding: 40px;
}

.energy__title {
  display: block;
  font-size: 20px;
}

.energy__link {
  display: block;
  margin: 25px;
}

.requisites {
  margin: 40px auto;
}

.requisites__list {
  list-style: none;
  display: grid;
  grid-template-columns: 1 1;
  gap: 15px;
}

.requisites__item_imp {
  font-weight: bold;
  font-size: 20px;
  grid-column: 1;
}

.requisites__item {
  align-items: end;
  font-size: 20px;
  grid-column: 2;
}
@media screen and (max-width: 550px) {
  .important__video_desk {
    display: none;
  }
  .important__video_mob {
    display: block;
    transform: translateX(-10px);
  }

  .requisites__item {
    grid-column: 1;
  }
  .requisites__list {
    grid-template-columns: 100%;
  }
}

.item__img {
  height: 120px;
  width: 120px;
  box-sizing: border-box;
}
.smallImg {
  height: 40vh;
  box-sizing: content-box;
  transition: ease-in 0.3s;
}
.pdf__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.bigImg {
  height: 100vh;
  scale: 1.2;
  transition: ease-in 0.3s;
}

.wrapper_for_docs {
  display: flex;
  flex-direction: column;
}

.answer__wrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
}

.answer__button {
  background-color: #fff;
  border: none;
  margin: 30px auto;
  display: block;
}

.doc_h1 {
  margin: 10px auto;
  text-align: center;
  font-size: 30px;
}

.documents__body {
  padding: 30px 60px 30px 30px;
  background-color: #f1f1f1;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  font-size: 20px;
  color: #393939;
}

.documents__button {
  position: relative;
  width: 100%;
  padding: 20px 60px 20px 30px;
  background-color: #ffffff;
  border: 0;
  border-radius: 7px;
  font-size: 22px;
  text-align: left;
  cursor: pointer;
}

.documents__item {
  padding: 20px 60px 20px 30px;
  border: 0;
  border-radius: 7px;
  font-size: 22px;
  text-align: left;
  cursor: pointer;
}

.doc__collapse {
  height: 0;
  overflow: hidden;
  transition: height 0.3s;
}

.doc__collapse_open {
  height: auto;
}

.doc__icon {
  position: absolute;
  top: 50%;
  right: 20px;
  display: block;
  width: 30px;
  height: 17px;
  fill: rgb(255, 123, 0);
  transform: translateY(-50%);
}

.doc__icon_active {
  transform: translateY(-50%) rotate(180deg);
  fill: green;
}

.accordion {
  list-style-type: none;
  margin: 16px;
  padding: 16px;
  background-color: rgba(37, 97, 143, 0.2);
  border-radius: 7px;
}

.accordion-item {
  margin-bottom: 16px;
  background-color: #ffffff;
  border-radius: 7px;
}

.accordion-header {
  position: relative;
  width: 100%;
  padding: 20px 60px 20px 30px;
  background-color: #ffffff;
  border: 0;
  border-radius: 7px;
  font-size: 22px;
  text-align: left;
  cursor: pointer;
}

.accordion-collapse {
  height: 0;
  overflow: hidden;
  transition: height 0.3s;
}

.accordion-collapse.open {
  height: 200px;
}

.accordion-body {
  padding: 30px 60px 30px 30px;
  background-color: rgba(37, 97, 143, 0.2);
  font-size: 20px;
  color: #393939;
}

.accordion-arrow {
  position: absolute;
  top: 50%;
  right: 20px;
  display: block;
  width: 30px;
  height: 17px;
  fill: rgb(255, 123, 0);
  transform: translateY(-50%);
}

.accordion-arrow.active {
  transform: translateY(-50%) rotate(180deg);
  fill: green;
}

.dues__wrapper {
  margin: 50px 0px;
  padding: 0 40px;
}

.links__list {
  display: block;
}

.doc__links {
  display: block;
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.doc__container {
  margin-top: 40px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.link_to_doc {
  margin: 20px 0;
  text-decoration: none;
  background-color: rgba(37, 97, 143, 0.2);
  color: #000;
  cursor: pointer;
  border-radius: 5px;
  padding: 15px;
  display: block;
  width: fit-content;
}

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
}
@media screen and (max-width: 800px) {
  .wrapper {
    flex-direction: column;
    gap: 0px;
  }
  
}

.img_of_doc {
  max-width: 60vh;
}

.doc__button {
  margin: 10px 15px;
}

.doc__wrapper {
  max-height: 140vh;
}

.annotationLayer {
  height: 0px;
  width: 0px;
}

.doc__text {
  z-index: 100;
}

.egrul__container {
  display: flex;
  flex-direction: column;
}

.button__wrapper {
  margin: 17px;
  opacity: 0.5;
}

.button__link {
  padding: 11px 9px;
  color: #939fa7;
  background-color: rgba(37, 97, 143, 0.2);
  border: none;
  border-radius: 5px;
}
.navTab__wrapper {
  position: fixed;
  right: 0;
  top: 0;
  opacity: 0.9;
  z-index: 50;
  transform: translateX(0%);
  transition: all 0.5s;
  background-color: rgb(69, 118, 156);
}

.navTab__wrapper-inactive {
  position: fixed;
  right: 0;
  z-index: 50;
  transform: translateX(150%);
  transition: all 2s;
}

.navTab__wrapper::before {
  content: " ";
  background-color: rgba(53, 155, 233, 0.3);
  position: absolute;
  opacity: 0.3;
  right: 0;
  width: 100vw;
  height: 100vh;
  z-index: -3;
}

.navTab__close {
  background-image: url(../public/close.svg);
  padding: 18px;
  border: none;
  background-color: #202020;
  position: absolute;
  top: 22px;
  right: 19px;
  cursor: pointer;
  transition: opacity 0.5s;
}

.navTab__close:hover {
  opacity: 0.7;
}

.navTab__list {
  height: 100vh;
  width: 520px;
  background-color: #202020;
  padding: 15px 0 90px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 28px;
}

.navTab__link {
  display: block;
  color: white;
  text-decoration: none;
  font-size: 18px;
  font-weight: 500;
}

.navTab__link-active {
  text-decoration: none;
  color: white;
  font-size: 18px;
  font-weight: 500;
  padding-bottom: 5px;
  border-bottom: 2px solid white;
}

.navTab__profile {
  padding: 595px 0 0 10px;
}

@media screen and (max-width: 790px) {
  .navTab__list {
    width: 100vw;
    padding: 145px 0 0;
    gap: 23px;
  }

  .navTab__close {
    top: 13px;
    right: 10px;
  }

  .navTab__profile {
    padding: 424px 0 0 15px;
  }
}

.headerForUser__nav {
  display: none;
}

@media screen and (max-width: 820px) {
  .headerForUser {
    padding: 0 30px;
  }
}

.navButton {
  width: 13px;
  height: 22px;
  margin-right: 26px;
  position: relative;
  background: none;
  border: none;
  cursor: pointer;
  transition: opacity 1s;
}

.navButton:hover {
  opacity: 0.8;
}

.navButton:before {
  content: "";
  position: absolute;
  top: 0;
  width: 28px;
  background-color: white;
  height: 3px;
}

.navButton:after {
  content: "";
  position: absolute;
  bottom: 0;
  width: 28px;
  background-color: white;
  height: 3px;
}

.navButton__icon {
  position: absolute;
  top: 9px;
  width: 28px;
  background-color: white;
  height: 3px;
}

@media screen and (max-width: 520px) {
  .navButton::before {
    width: 23px;
  }

  .navButton::after {
    width: 23px;
  }

  .navButton__icon {
    width: 23px;
  }
}

@media screen and (max-width: 800px) {
  .headerForUser__wrapper {
    display: none;
  }

  .headerForUser__profile-link {
    display: none;
  }

  .headerForUser__nav {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: opacity 1s;
  }

  .headerForUser__nav:hover {
    opacity: 0.8;
  }
}

@media screen and (max-width: 580px) {
  .headerForUser {
    padding: 0 14px;
  }
}

.navButton {
  width: 13px;
  height: 22px;
  margin-right: 26px;
  position: relative;
  background: none;
  border: none;
  cursor: pointer;
  transition: opacity 1s;
}

.navButton:before {
  content: "";
  position: absolute;
  top: 0;
  width: 28px;
  background-color: white;
  height: 3px;
}

.navButton:after {
  content: "";
  position: absolute;
  bottom: 0;
  width: 28px;
  background-color: white;
  height: 3px;
}

.navButton__icon {
  position: absolute;
  top: 9px;
  width: 28px;
  background-color: white;
  height: 3px;
}

@media screen and (max-width: 520px) {
  .navButton::before {
    width: 23px;
  }

  .navButton::after {
    width: 23px;
  }

  .navButton__icon {
    width: 23px;
  }
}
